<template>
  <div class="dropzone">
    <div class="dropzone-wrap">
      <input
        type="file"
        placeholder=""
        multiple
        :required="required"
        @change="changeImage"
      />
      <slot
        v-if="!files.length"
        name="placeholder"
      ></slot>
      <div
        class="drop-image-wrap"
        v-for="(file, i) in files"
        :key="i"
      >
        <div
          class="drop-image"
          v-if="isImage(file)"
        >
          <img :src="file.preview" />
        </div>
        <div
          v-else
          class="svg drop-image"
        >
          <svg
            width="54"
            viewBox="0 0 494 512"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="svg">
                <path
                  id="Path"
                  d="M485.142,80.275 L417.066,9.301 C413.563,5.65 409.19,2.965 404.429,1.444 L404.429,92.13 L492.162,92.13 C490.784,87.734 488.408,83.681 485.142,80.275 Z"
                  fill="#868686"
                  fill-rule="nonzero"
                />
                <path
                  id="Path"
                  d="M389.413,122.148 C381.127,122.148 374.402,115.433 374.402,107.137 C374.402,107.067 374.412,106.997 374.422,106.917 C374.412,106.847 374.402,106.777 374.402,106.697 L374.402,0 L125.882,0 C109.23,0 95.68,13.55 95.68,30.212 L95.68,99.488 L244.721,99.488 C273.231,99.488 296.437,122.682 296.437,151.204 L296.437,250.473 C296.437,278.983 273.23,302.189 244.721,302.189 L95.68,302.189 L95.68,481.798 C95.68,498.45 109.231,512 125.883,512 L463.347,512 C479.999,512 493.549,498.45 493.549,481.798 L493.549,122.148 L389.413,122.148 Z"
                  fill="#868686"
                  fill-rule="nonzero"
                />
                <path
                  id="Path"
                  d="M229.287,124.74 L39.1,124.74 C17.785,124.74 0.452,142.073 0.452,163.388 L0.452,237.572 C0.452,258.877 17.785,276.22 39.1,276.22 L229.287,276.22 C250.592,276.22 267.935,258.877 267.935,237.572 L267.935,163.388 C267.935,142.073 250.593,124.74 229.287,124.74 Z"
                  fill="#868686"
                  fill-rule="nonzero"
                />
                <text
                  id="SVG"
                  font-family="ArialMT, Arial"
                  font-size="80"
                  font-weight="normal"
                  fill="#FFFFFF"
                >
                  <tspan
                    x="40"
                    y="224"
                  >{{ file.ext }}</tspan>
                </text>
              </g>
            </g>
          </svg>
        </div>
        <p> {{ file.name }}</p>
        <img
          @click.stop="files.splice(i, 1)"
          class="remove-img"
          src="../assets/close.svg"
          alt=""
        >
      </div>
      <slot
        v-if="files.length"
        name="comment"
      ></slot>
    </div>
    <Teleport to="body">
      <div
        class="popup"
        :class="{ openPop }"
      >
        <img
          width="18"
          height="18"
          @click="openPop = ''"
          class="close-popup"
          src="../assets/close-white.svg"
          alt="close"
        >
        <img
          class="popup-img"
          :src="openPop"
        >
      </div>
    </Teleport>
  </div>
</template>

<script>
import Teleport from '@/components/Teleport.vue'
export default {
  name: 'ImageRef',
  components: {
    Teleport
  },
  props: {
    value: { type: Array, default: () => [] },
    required: { type: Boolean, default: () => false }
  },
  data() {
    return {
      uploading: false,
      files: this.value,
      openPop: "",

    };
  },
  watch: {
    value() {
      this.val = this.value;
    },
  },
  methods: {
    isImage(file) {
      return file.type.match(/image.*/);
    },
    async changeImage(event) {
      const files = Array.from(event.target.files);

      // Create a promise for each file to read it as data URL
      const filePromises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            file.preview = e.target.result;
            file.ext = file.name.split('.').pop();
            resolve();
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      // Wait for all promises to resolve (i.e., all files are read)
      try {
        await Promise.all(filePromises);
      } catch (error) {
        console.error('Error reading files:', error);
        return;
      }

      for (const file of files) {
        const index = this.files.findIndex((f) => f.name === file.name);
        if (index !== -1) {
          this.files.splice(index, 1);
        } else {
          this.files.push(file);
        }
      }

      this.$emit('input', this.files);
    },
  },
};
</script>

<style scoped>
.popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease;
  display: none;
  position: fixed;
  z-index: 199;
  height: 100vh;
  width: 100vw;
}

.popup.openPop {
  display: flex;
}

.close-popup {
  position: absolute;
  inset-inline-start: 1.5rem;
  top: 0rem;
  padding: 10px;
  transition: all 200ms ease;
  cursor: pointer;
}

.close-popup:hover {
  filter: brightness(90%);
}

.close-popup:active {
  filter: brightness(80%);
}

.popup-img {
  height: 80vh !important;
  max-width: 90vw;
  object-fit: contain;
}

.box {
  width: 100%;
}

.svg {
  /* visibility: hidden; */
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drop-image-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #dadedf;
  margin-bottom: 10px;
  padding: 5px;
  z-index: 99;
  position: relative;
  cursor: zoom-in;
}

.drop-image {
  height: 60px;
  width: 60px;
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drop-image img {
  margin: 0;
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.drop-image-wrap p {
  margin: 0;
  font-size: 19px;
  text-align: left;
  color: black;
  line-height: 1.3;
  word-break: break-all;

}

.over-img {
  height: 50px;
  width: 50px;
  position: absolute;
  margin: 0 auto;
  top: 30px;
  left: 0;
  right: 0;
  opacity: 0.5;
  background: #95989b;
  border-radius: 100%;
  padding: 10px 0;
  /* filter: invert(); */
  transition: all 200ms ease;
}

.remove-img {
  height: 20px;
  padding: 5px;
  border-radius: 100%;
  background: white;
  margin: 8px 8px 8px auto;
  transition: all 200ms ease;
  cursor: pointer;
  z-index: 99;
  flex: 0 1 20px;
}

.remove-img:hover {
  filter: brightness(90%);
}

.remove-img:active {
  filter: brightness(80%);
}


.box:hover .over-img {
  opacity: 0.9;
}

.eraseimg {
  color: red;
  margin-top: -23px;
  font-size: 15px;
}

@keyframes loader-animation {
  0% {
    width: 0%;
    left: 0%;
    right: 0%;
  }

  40% {
    width: 100%;
    left: 100%;
    right: 0%;
  }

  85% {
    width: 0%;
    left: 0%;
    right: 100%;
  }

  100% {
    width: 0%;
    right: 0%;
    left: 0%;
  }
}

.loader {
  height: 2px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.loader .bar {
  position: relative;
  height: 5px;
  background-color: #ff7272;
  animation-name: loader-animation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.imageUploader {
  padding: 10px;
  background-color: white;
  max-width: 500px;
  height: 100;
  text-align: center;
}

.uploadedImage {
  height: 120px;
  max-width: 100%;
  object-fit: scale-down;
  object-position: top;
}

.uploadedImage.uploading {
  opacity: 0.6;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}

.box {
  margin-bottom: 10px;
  overflow: hidden;
  outline: 2px dashed #dadedf;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
}

.box {
  font-size: 1.25rem;
  background-color: #f4f7f8;
  position: relative;
  padding: 30px 20px;
  margin: 0 -10px;
  text-align: center;
  display: inline-block;
  width: 120px;
  height: 120px;
}

.box__icon {
  width: 100%;
  height: 40px;
  fill: #bbbbbb;
  display: block;
  margin-bottom: 10px;
}

.box__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.box label {
  max-width: 80%;
  color: #bbbbbb;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
}


@media screen and (max-width: 767px) {

  .drop-image-wrap {
    margin: 10px 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
</style>
