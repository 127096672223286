<template >
	<div>

		<topnav></topnav>
		<div class="page-wrap">
			<div class="evaluation-page">
				<Carousel
					:nav="false"
					:dots="false"
					:items="1"
					:autoHeight="true"
					:touchDrag="false"
					:pullDrag="false"
					:freeDrag="false"
					ref="carouselRef"
					:mouseDrag="false"
					@change="handleSlideChange"
					@owlReady="handleOwlReady"
				>

					<div class="step">
						<!-- {{ $t('Get the best offer') }} -->
						<h1
							class="txt88 serif light"
							v-if="$lang === 'en'"
						>{{ $local.formConfiguration.name }}</h1>
						<h1
							class="txt88 serif light"
							v-if="$lang === 'fr'"
						>{{ $local.formConfiguration.titleFrench ||
							$local.formConfiguration.name }}</h1>
						<p class="evaluation-sub"> {{ $t('What type of jewelry do you have') }}?</p>
						<p class="evaluation-instraction"> {{ $t('Select an option') }}</p>
						<div class="evaluation-wrap">

							<div
								v-for="item, idx in options"
								:key="item._id"
								class="evaluation-item big"
								@click="handleSelectOpt(idx)"
								:class="{ evaluationactive: selectedOptIdx === idx }"
							>
								<img
									:src="item.icon.imageURL"
									:alt="item.optionName"
								>
								<p v-if="$lang === 'en'">{{ item.optionName }}</p>
								<p v-if="$lang === 'fr'">{{ item.optionNameFrench || item.optionName }}</p>
							</div>
						</div>
					</div>
					<div class="step">
						<p
							v-if="form.jewelry_type_menu != 'Diamonds'"
							class="evaluation-sub"
						> {{ $t('Choose a type') }}</p>
						<p
							v-if="form.jewelry_type_menu == 'Diamonds'"
							class="evaluation-sub"
						> {{ $t('Select the carat weight of your diamond') }}</p>

						<p class="evaluation-instraction"> {{
							$t('Select an option') }}
						</p>
						<div class="evaluation-wrap">

							<div
								v-for="subOption, idx in selectedSubOpts"
								:key="subOption._id"
								class="evaluation-item"
								@click="handleSelectSubOpt(idx)"
								:class="{ evaluationactive: form.sub_option === subOption.name }"
							>
								<p v-if="$lang === 'en'">{{ subOption.name }}</p>
								<p v-if="$lang === 'fr'">{{ subOption.nameFrench || subOption.name }}</p>
							</div>
						</div>
					</div>
					<div class="step">
						<p class="evaluation-sub">{{ $t(`We're almost there, enter your info and get a free evaluation`) }}.
						</p>

						<form
							@submit.prevent
							ref="formRef"
							autocomplete="off"
							class="evaluation-form"
						>
							<div class="evaluation-form-flex">
								<div>
									<label class="evaluation-label">{{ $t('Name') }}</label>
									<input
										type="text"
										:placeholder="$t('Name') + '*'"
										name="Name"
										class="txt-field"
										required
										v-model="form.firstname"
										autocomplete="given-name"
									/>
								</div>
								<div>
									<label class="evaluation-label">{{ $t('Surname') }}</label>
									<input
										type="text"
										:placeholder="$t('Surname') + '*'"
										name="Surname"
										class="txt-field"
										required
										v-model="form.lastname"
										autocomplete="family-name"
									/>
								</div>
							</div>
							<label class="evaluation-label">{{ $t('Email') }}</label>
							<input
								type="email"
								:placeholder="$t('Email') + '*'"
								name="email"
								class="txt-field"
								required
								pattern="[\w'\d._%+\-]+@[\w\d.\-]+.[\w]{2,5}"
								title="Please enter a valid email"
								v-model="form.email"
								autocomplete="off"
							/>

							<div class="vue-tel-input-wrap">
								<label class="evaluation-label">{{ $t('Phone') }}</label>
								<vue-tel-input
									translate="no"
									:inputOptions="{ placeholder: $t('Phone') + '*', required: true }"
									:dropdownOptions="{
										showDialCodeInSelection: true,
										showFlags: true,
										showSearchBox: true,
									}"
									:onlyCountries="['AL', 'AD', 'AT', 'BY', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FO', 'FI', 'FR', 'GE', 'DE', 'GR', 'HU', 'IS', 'IE', 'IT', 'XK', 'LV', 'LI', 'LT', 'LU', 'MT', 'MD', 'MC', 'ME', 'NL', 'MK', 'NO', 'PL', 'PT', 'RO', 'RU', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'UA', 'GB', 'IL']"
									:autoFormat="true"
									mode="international"
									type="tel"
									inputmode="numeric"
									autocomplete="tel"
									class="txt-field"
									defaultCountry="FR"
									invalidMsg="Invalid phone number"
									:validCharactersOnly="true"
									v-model="form.phone"
									@input="setWhatsapp"
								></vue-tel-input>

							</div>

						</form>
					</div>
					<div class="step">
						<label
							class="evaluation-instraction"
							for=""
						>
							{{ $t('Do you have photos of the product and/or a photo of the certificate?') }}

							<div class="evaluation-yes-no">
								<button
									class="evaluation-item big"
									@click="photosNext(true)"
								> {{ $t('YES') }}</button>
								<button
									class="evaluation-item big"
									@click="photosNext(false)"
								>{{ $t('Not Right Now')
								}}</button>
							</div>
						</label>
					</div>
					<div class="step">
						<p
							v-if="!iHavePhotos"
							class="evaluation-instraction"
						>
							{{ $t('Please describe the product') }}
						</p>
						<form
							class="evaluation-files"
							@submit.prevent
							ref="formRef2"
							autocomplete="off"
						>
							<textarea
								v-if="!iHavePhotos"
								class="txt-field evaluation-textarea"
								required
								:placeholder="$t('Description')"
								v-model="form.description"
							>
                </textarea>
							<template v-else>
								<!-- <p>
                  {{ $t('Please insert your photos here') }}
                </p> -->
								<ImageRef
									v-model="form.image1"
									:required="true"
								>
									<template #placeholder>
										<img src="../assets/camera.svg" />
										<p class="dropzone-smaller-txt">
											{{ $t('Adding images enables a more precise estimate. Please photograph your jewelry beside a ruler in natural light.') }}
										</p>
										<a
											class="dropzone-link"
											target="_blank"
											:href="getInstruction()"
										>
											{{ $t('For Further Instruction') }} {{ '>' }}
										</a>
										<button class="dropzone-btn">+ {{ $t('Add an image') }}</button>

									</template>
									<template #comment>
										<button class="dropzone-btn">+ {{ $t('Add another image') }}</button>
									</template>
								</ImageRef>

								<ImageRef v-model="form.image2">
									<template #placeholder>
										<img src="../assets/note.svg" />
										<p class="dropzone-smaller-txt">
											{{ $t('If you have a certificate for your valuables, please upload it here') }}
										</p>
										<button class="dropzone-btn">+ {{ $t('Add a Certificate') }}</button>
									</template>
									<template #comment>
										<button class="dropzone-btn">+ {{ $t('Add another image') }}</button>
									</template>
								</ImageRef>
							</template>

						</form>
					</div>
				</Carousel>
				<div class="evaluation-btns-wrap">
					<button
						class="evaluation-back-btn"
						@click="triggerPrevStep"
						v-if="carouselSlideIndex"
					>
						<img
							src="../assets/small-arrow-dark.svg"
							alt=""
						>
						{{ $t('Back') }}

					</button>
					<button
						class="evaluation-btn"
						@click="handleFormValidation"
						v-if="carouselSlideIndex === 2"
					>
						{{ $t('Next') }}
					</button>
					<button
						class="evaluation-btn"
						:disabled="submitting"
						v-if="carouselSlideIndex === 4"
						@click="submitEvaluationRequest"
					>
						{{ $t('Submit') }}
					</button>
				</div>
			</div>


			<!-- <p class="form-title">
        {{ $t('First, please fill in a few details below:') }}
      </p> -->
			<HsForm
				portalId="25499998"
				formId="45c61f3d-42fd-4cbd-9570-d6a8eb5d2e4b"
				region="eu1"
				v-model="form"
				@submitFunc="providedForm"
			/>

			<div
				class="form-fail"
				v-if="formStatus == 'err'"
			>

				<p
					class="form-fail"
					v-html="errorMsg || $t('An error occurred while sending')"
				></p>

			</div>
			<div
				class="form-success"
				v-if="formStatus === 'success'"
				v-html="successMessage || $t('Thank you!') + '<br />' + $t('submission has been received')"
			>
			</div>
		</div>
		<div class="hide-mobile">
			<botfooter></botfooter>
		</div>
	</div>
</template>

<script>
// import BglCarousel from '@/components/bglCarousel.vue';
import { Carousel } from "bgl-v-owl-carousel"
// import Carousel from '@/components/Carousel.vue';
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import HsForm from '@/components/HsForm.vue';
import ImageRef from '@/components/ImageRef.vue';
import { formConfiguration } from '@/data/localData.json'

import { VueTelInput } from 'vue-tel-input';
import '@/vue-tel-input.css';
import { db } from '@/bagel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
export default {
	components: {
		// BglCarousel,
		Topnav,
		Botfooter,
		Carousel,
		VueTelInput,
		HsForm,
		ImageRef
	},
	data() {
		return {
			bagelSubmissionID: '',
			submitting: false,
			formRef: null,
			formRef2: null,
			errorMsg: '',
			form: {
				firstname: '',
				lastname: '',
				email: '',
				phone: '',
				hs_whatsapp_phone_number: '',
				jewelry_type_menu: [],
				message: '',
				sub_option: '',
				image1: [],
				image2: [],
				description: '',
			},
			submitFun: null,
			formStatus: '',
			successMessage: '',
			selectedOptIdx: undefined,
			selectedSubOptIdx: undefined,

			carouselRef: null,
			carouselSlideIndex: undefined,
			iHavePhotos: null,

			invalidEmails: [
				'test@test.com',
				'test@gmail.com',
				'test@gnail.com',
				'123@gmail.com',
				'test@gnail.com'
			],
		};
	},
	methods: {
		async submitToBagel() {
			const form = { ...this.form, ...this.$route.query }
			if (!form.email) return
			form.query = this.$route.query
			form.url = window.location.href
			if (this.bagelSubmissionID) {
				if (form.note) form.status = "With Description"
				else form.status = "With Image"
				await db.collection("formSubmissions").item(this.bagelSubmissionID).put(form)
			} else {
				form.status = "Personal Details"
				const { data } = await db.collection("formSubmissions").post(form)
				this.bagelSubmissionID = data.id
			}
		},
		isValidEmail() {
			this.errorMsg = ''
			this.formStatus = ''
			const { email } = this.form;
			const invalidRegex = /^test@/
			const res = !this.invalidEmails.includes(email) && !email.match(invalidRegex)
			if (!res) {
				this.formStatus = 'err';
				this.errorMsg = this.$t('Please enter a valid email')
			}
			return res
		},
		setWhatsapp() {
			this.form.hs_whatsapp_phone_number = this.form.phone
		},
		getInstruction() {
			return this.options[this.selectedOptIdx]?.instructionFile?.imageURL || "/Pictures-instruction.pdf"
		},
		photosNext(val) {
			this.iHavePhotos = val
			this.triggerNextStep()
		},
		handleFormValidation() {
			if (!this.isValidEmail() || !this.$refs?.['formRef']?.reportValidity?.()) return

			this.triggerNextStep()
		},
		providedForm($event) {
			this.submitFun = $event
		},
		async submitForm() {
			if (this.submitting) return;
			try {
				this.submitting = true
				const res = await this.submitFun()

				if (res.redirectUrl)
					window.location.href = res.redirectUrl + `&withImage=${!!(this.form.image1.length || this.form.image2.length)}`;
				if (res.inlineMessage) this.successMessage = res.inlineMessage
				this.formStatus = 'success';
				this.submitting = false
			} catch (err) {
				this.formStatus = 'err';
				this.errorMsg = err
				this.submitting = false
			}
		},

		handleSelectOpt(idx) {
			this.selectedOptIdx = idx;
			this.form.jewelry_type_menu = [this.options[idx].optionName];
			this.form.message = this.form.jewelry_type_menu
			this.triggerNextStep()
		},

		handleSelectSubOpt(idx) {
			this.selectedSubOptIdx = idx;
			this.form.sub_option = this.selectedSubOpts[idx].name
			this.triggerNextStep()
			this.$gtag('form_pageview')
		},
		triggerRefresh() {
			this.submitToBagel()
			setTimeout(() => this.$refs?.['carouselRef']?.triggerRefresh(), 0);
		},
		triggerNextStep() {
			this.$refs?.['carouselRef']?.triggerNext()
			this.triggerRefresh()
		},
		triggerPrevStep() {
			this.$refs?.['carouselRef']?.triggerPrev()
			this.triggerRefresh()
		},
		handleSlideChange(e) {
			this.carouselSlideIndex = e.item.index;
		},
		async submitEvaluationRequest() {
			if (!this.$refs?.['formRef2']?.reportValidity?.()) return

			if (this.submitting) return;
			try {
				this.submitting = true
				const res = await this.submitFun()
				if (res.redirectUrl)
					window.location.href = res.redirectUrl + `&withImage=${!!(this.form.image1.length || this.form.image2.length)}`;
				if (res.inlineMessage) this.successMessage = res.inlineMessage
				this.formStatus = 'success';
				this.submitting = false
			} catch (err) {
				this.$refs?.['carouselRef']?.slideIntoView(2)
				this.formStatus = 'err';
				this.errorMsg = err
				this.submitting = false
			}
		},

		handleOwlReady() {
			const navOption = this.$route.query?.option;
			if (navOption !== 0 && !navOption) return;
			this.handleSelectOpt(navOption)
		},
	},
	computed: {
		$local() {
			return { formConfiguration }
		},
		options() {
			return this.$local.formConfiguration.options
		},
		selectedSubOpts() {
			return this.options[this.selectedOptIdx]?.subOptions || []
		}
	},

	name: "Form"
};
</script>

<style scoped>
.evaluation-textarea {
	max-width: 700px;
	width: 90%;
	margin: 0px auto;
	min-height: 260px;
	resize: none;
	/* padding: 10px; */
}

.evaluation-yes-no {
	display: flex;
	gap: 30px;
	justify-content: center;
	margin: 30px;
}

.evaluation-label {
	text-align: left;
	width: 100%;
	display: block;
	font-size: smaller;
}

.evaluation-form-flex>div {
	text-align: left;
	width: 100%;
}

.vti__dropdown-list.above {
	max-height: 150px;
}

.vti__dropdown-list.below {
	top: 58px !important;
	max-height: 170px;

}

.vti__dropdown-list strong {
	font-weight: 300;
	font-size: 18px;
}

.vti__dropdown-list {
	z-index: 995 !important;
	min-width: 300px;
	max-width: 570px !important;
	width: 100vw !important;
}

.vti__search_box {
	position: sticky;
	top: 0;
	margin: 0 !important;
	width: 100% !important;
	border: none !important;
	border-bottom: #BEB6AF 1px solid !important;
}

.vti__search_box::placeholder {
	opacity: 0.4;
}

.vue-tel-input-wrap {
	padding-bottom: 140px;
}

.vue-tel-input {
	border-radius: 0 !important;
	background: white;
	padding: 0;

}

.vue-tel-input:focus-within {
	box-shadow: none !important;
	border-color: transparent !important;
	outline: 2px solid #736B67;
	border: 1px solid #BEB6AF !important;

}

.vue-tel-input:focus-visible {
	outline: 2px solid #736B67;
	border: 1px solid #BEB6AF;
}

.error {
	background-color: red;
	color: white;
	line-height: 1;
	padding: 8px;
	margin-top: 0;
	font-size: 16px;
	text-align: center;
}

.form-btn.img {
	color: #827C78;
	margin-top: 15px;
	font-size: 20px;
}

.hide {
	display: none;
}

@media screen and (max-width: 767px) {
	.vti__dropdown-list {
		min-width: 200px;
		max-width: 562px !important;
		width: 100vw !important;
	}
}

@media screen and (max-width: 610px) {
	.vti__dropdown-list {
		width: calc(100vw - 48px) !important;
	}
}
</style>



<style>
.vti__dropdown-list.above {
	max-height: 150px;
}

.evaluation-page .vti__dropdown-list.below {
	top: 58px !important;
	max-height: 170px;

}

.vti__dropdown-list strong {
	font-weight: 300;
	font-size: 18px;
}

.evaluation-page .vti__dropdown-list {
	z-index: 995 !important;
	min-width: 300px;
	max-width: 570px !important;
	width: 100vw !important;
}

.vti__search_box {
	position: sticky;
	top: 0;
	margin: 0 !important;
	width: 100% !important;
	border: none !important;
	border-bottom: #BEB6AF 1px solid !important;
}

.vti__search_box::placeholder {
	opacity: 0.5;
}

@media screen and (max-width: 767px) {
	.evaluation-page .vti__dropdown-list {
		z-index: 995 !important;
		min-width: 300px;
		max-width: 327px !important;
		width: 100vw !important;
	}
}
</style>